import React, { useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../context/appContext';

const About = () => {

    const { stores, picUrl } = useContext(AppContext)
    const location = useLocation();

    useEffect(() => {
        if (window.myCustomScript) {
            window.myCustomScript();
        }
    }, [location]);

    return (
        <>
            {/* hero-section  */}
            <section className="hero-section about gap" style={{backgroundImage: "url(assets/img/background-1.png)"}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12" data-aos="fade-up"  data-aos-delay="300" data-aos-duration="400">
                            <div className="about-text">
                                <ul className="crumbs d-flex">
                                    <li><Link to={'/home'}>Home</Link></li>
                                    <li className="two"><a href="index.html"><i className="fa-solid fa-right-long"></i> About Us</a></li>
                                </ul>
                                <h2>Experience Hassle-Free Food Delivery with Delivas</h2>
                                <p>At Delivas, we connect you with your favorite local restaurants and ensure your meals are delivered fast and fresh. Whether you're craving comfort food or something new, we've got you covered.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12" data-aos="fade-up"  data-aos-delay="400" data-aos-duration="500">
                            <div className="about-img">
                                <img alt="man" src="./assets/img/photo-9.jpg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* our-mission-section  */}
            <section className="our-mission-section gap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12" data-aos="flip-up"  data-aos-delay="300" data-aos-duration="400">
                            <div className="our-mission-img">
                                <img alt="Illustration" src="./assets/img/illustration-5.png" />
                            </div>
                        </div>
                        <div className="offset-xl-1 col-lg-5 col-md-12 col-sm-12" data-aos="flip-up"  data-aos-delay="400" data-aos-duration="500">
                            <div className="our-mission-text">
                                <h2>Our Mission: Saving You Time and Satisfying Your Cravings</h2>
                                <p>At Delivas, we believe in making food delivery seamless and efficient. Our mission is to help you reclaim time in your busy day, whether you're ordering from your local go-to or discovering new flavors around the corner. Fast, convenient, and reliable service is our promise to you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* cards-section */}
            <section className="cards-section gap no-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" data-aos="flip-up"  data-aos-delay="200" data-aos-duration="300">
                            <div className="card-text-data">
                                <img className="on" alt="icon" src="assets/img/service-icon-2.svg" />
                                <img className="off" alt="icon" src="assets/img/service-icon-1.svg" />
                                <h3>Affordable Delivery</h3>
                                <p>Get your favorite dishes delivered straight to your door at almost no cost. Enjoy delicious meals without the delivery charge.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" data-aos="flip-up"  data-aos-delay="300" data-aos-duration="400">
                            <div className="card-text-data two">
                                <img className="on" alt="icon" src="assets/img/service-icon-3.svg" />
                                <img className="off" alt="icon" src="assets/img/service-icon-4.svg" />
                                <h3>Save Your Time</h3>
                                <p>No need to wait in line or spend time cooking. Let us bring the food to you quickly so you can focus on what matters most.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" data-aos="flip-up"  data-aos-delay="400" data-aos-duration="500">
                            <div className="card-text-data">
                                <img className="on" alt="icon" src="assets/img/service-icon-5.svg" />
                                <img className="off" alt="icon" src="assets/img/service-icon-6.svg" />
                                <h3>Regular Discounts</h3>
                                <p>Take advantage of exclusive offers and discounts on your orders. We work with restaurants to bring you special deals year-round.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" data-aos="flip-up"  data-aos-delay="500" data-aos-duration="600">
                            <div className="card-text-data two">
                                <img className="on" alt="icon" src="assets/img/service-icon-7.svg" />
                                <img className="off" alt="icon" src="assets/img/service-icon-8.svg" />

                                <h3>Wide Variety of Cuisines</h3>
                                <p>Whether you're craving pizza, sushi, or a gourmet burger, Delivas has a vast selection of dishes from all types of cuisines.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* video-section */}
            <section className="video-section gap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6" data-aos="fade-up"  data-aos-delay="200" data-aos-duration="300">
                            <div className="wait-a-minute">
                            <h2>A Taste of Convenience at Your Fingertips</h2>
                            <p>With just a few taps, enjoy hot and delicious meals delivered right to you. Watch how Delivas simplifies your food ordering experience.</p>
                            <h6>Discover the simplicity of getting food delivered effortlessly.</h6>
                                <ul className="paragraph">
                                    <li><i className="fa-solid fa-circle-check"></i><h5>Fast and reliable delivery to your doorstep</h5></li>
                                    <li><i className="fa-solid fa-circle-check"></i><h5>Wide selection of local and international cuisines</h5></li>
                                    <li><i className="fa-solid fa-circle-check"></i><h5>Exclusive deals and discounts available regularly</h5></li>
                                    <li><i className="fa-solid fa-circle-check"></i><h5>Real-time order tracking for peace of mind</h5></li>
                                    <li><i className="fa-solid fa-circle-check"></i><h5>Easy-to-use app with secure payment options</h5></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="fade-up"  data-aos-delay="300" data-aos-duration="400">
                            <div className="video-section-img">
                                <img alt="elements" src="assets/img/elements-1.jpg" />
                                <a data-fancybox="" href="https://www.youtube.com/watch?v=CKnGXZxK7zs"><i className="fa-solid fa-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our partners Section */}
            <section className="our-partners-section gap" style={{backgroundColor: "#fcfcfc"}} data-aos="fade-up"  data-aos-delay="200" data-aos-duration="300">
                <div className="container">
                    <div className="hading">
                        <h2>Trusted Partners, Quality Service</h2>
                        <p>We partner with top restaurants in your area to ensure you always have access to a variety of options. From small local favorites to well-known establishments, our partners provide delicious meals you'll love.</p>
                    </div>
                    <div className="align-items-center owl-carousel owl-theme" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        {
                            stores.map((store, index) => (
                                <div key={index} className="item" style={{ flex: '0 0 auto' }}>
                                    <div className="logo-img">
                                        <img 
                                            alt={`${store.store_name}`} 
                                            src={`${picUrl}/${store.image}`} 
                                            style={{ width: '140px', height: '124px' }}
                                        />
                                        <h5>{store.store_name}</h5>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
