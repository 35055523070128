import React from 'react';

const PrivacyPolicy = () => {
    return (
        <>
            {/* hero-section */}
            <section className="hero-section single-blog gap" style={{backgroundImage: "url(assets/img/background-3.png)"}} data-aos="flip-up"  data-aos-delay="300" data-aos-duration="400">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="offset-xl-2 col-xl-6 col-lg-12">
                                <div className="about-text">
                                    <h2>Privacy Policy for our apps</h2>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            {/* tabs questions */}
            <section className="tabs">
                <div className="container">
                <div className="tabs-img-back">
                <div className="row">
                    <div className="offset-xl-2 col-xl-8 col-lg-12">
                        <div className="Provides">
                            <div className="nav nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link active" id="user-tab" data-bs-toggle="pill" data-bs-target="#user" type="button" role="tab" aria-controls="user" aria-selected="true">User App</button>
                                <button className="nav-link" id="driver-tab" data-bs-toggle="pill" data-bs-target="#driver" type="button" role="tab" aria-controls="driver" aria-selected="false">Driver App</button>
                                <button className="nav-link" id="merchant-tab" data-bs-toggle="pill" data-bs-target="#merchant" type="button" role="tab" aria-controls="merchant" aria-selected="false">Merchant App</button>
                            </div>
                        </div>
                    </div>

                    <div className="offset-xl-2 col-xl-8 col-lg-12 gap">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <h3 style={{marginBottom: 10}}>Delivas Privacy Policy for Users/Customers app</h3>
                                        <h3 style={{marginBottom: 50, fontSize: 20, textAlign: 'center'}}>Last Updated: 22/08/2024</h3>
                                        <div className="terms-container">
                                            <div className="terms-section">
                                                <h3>1. Introduction</h3>
                                                <p>Welcome to <strong>Delivas  App</strong> ("we", "our", "us"). This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our mobile application ("App"). Please read this Privacy Policy carefully. By using our App, you agree to the collection and use of information in accordance with this policy.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>2. Information We Collect</h3>
                                                <p>We collect personal information that you voluntarily provide to us when you register on the app, place an order, or contact us. The personal information we collect may include:</p>
                                                <p><strong>2.1.</strong> Contact Information: Name, email address, phone number.</p>
                                                <p><strong>2.2.</strong> Payment Information: Payment card details, billing address.</p>
                                                <p><strong>2.3.</strong> Order Details: Items ordered, delivery address, order history.</p>
                                                <p><strong>2.4.</strong> Location Data: If you allow location access, we collect data to provide delivery services.</p>
                                                <p><strong>2.5.</strong> Device Information: IP address, device type, operating system, and app usage data.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>3. How We Use Your Information</h3>
                                                <p>The information we collect may be used for the following purposes:</p>
                                                <p><strong>3.1.</strong> Order Processing: To process and deliver your orders, including managing payments and sending order updates </p>
                                                <p><strong>3.2.</strong> Customer Support: To respond to your inquiries, resolve issues, and provide customer support. </p>
                                                <p><strong>3.3.</strong> Personalization: To personalize your app experience by showing relevant content and promotions. </p>
                                                <p><strong>3.4.</strong> Marketing: To send you marketing and promotional communications if you have opted-in to receive them.</p>
                                                <p><strong>3.5.</strong> Analytics: To analyze app usage to improve our services and develop new features. </p>
                                                <p><strong>3.6.</strong> Legal Compliance: To comply with legal obligations and protect our rights. </p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>4. Sharing Your Information</h3>
                                                <p>We may share your personal information with:</p>
                                                <p><strong>4.1.</strong> Service Providers: Third-party vendors who provide services such as payment processing, delivery, and app analytics.</p>
                                                <p><strong>4.2.</strong> Business Transfers: In the event of a merger, sale, or transfer of company assets, your data may be transferred to the new owner.</p>
                                                <p><strong>4.3.</strong> Legal Obligations: To comply with legal obligations, such as responding to lawful requests by public authorities.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>5. Data Security</h3>
                                                <p><strong>5.1.</strong> We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>6. Your Rights</h3>
                                                <p>You have the right to:</p>
                                                <p><strong>6.1.</strong> Access: Request a copy of the personal data we hold about you.</p>
                                                <p><strong>6.2.</strong> Correction: Request corrections to your personal data if it is inaccurate.</p>
                                                <p><strong>6.3.</strong> Deletion: Request the deletion of your personal data, subject to certain exceptions.</p>
                                                <p><strong>6.4.</strong> Opt-Out: Unsubscribe from marketing communications at any time.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>7. Children's Privacy</h3>
                                                <p><strong>7.1.</strong> Our app is not intended for children under the age of 13, and we do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child under 13, we will take steps to delete it.</p>
                                            </div>
                                            
                                            <div className="terms-section">
                                                <h3>8. Modifications to Terms</h3>
                                                <p><strong>8.1.</strong>We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last Updated" date.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>9. Contact Us</h3>
                                                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                                                <p><strong>9.1.</strong> Email: info@delivas.co.za</p>
                                                <p><strong>9.1.</strong> Phone: 0794185925</p>
                                                <p><strong>9.1.</strong> Website: www.delivas.co.za</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="driver" role="tabpanel" aria-labelledby="driver-tab">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <h3 style={{marginBottom: 10}}>Delivas Privacy Policy for Drivers app</h3>
                                        <h3 style={{marginBottom: 50, fontSize: 20, textAlign: 'center'}}>Last Updated: 22/08/2024</h3>
                                        <div className="terms-container">
                                            <div className="terms-section">
                                                <h3>1. Introduction</h3>
                                                <p>Welcome to<strong>Delivas Driver App </strong>("we", "our", "us"). This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our mobile application ("App"). Please read this Privacy Policy carefully. By using our App, you agree to the collection and use of information in accordance with this policy.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>2. Information We Collect</h3>
                                                <p>We may collect the following types of information:</p>
                                                <p><strong>2.1. Personal Information: Name, phone number, email address, and other contact details.</strong></p>
                                                <p><strong>2.2. Location Data: Real-time location information when the App is in use.</strong></p>
                                                <p><strong>2.3. Device Information: Information about the device you use, including IP address, device ID, operating system, and app version.</strong></p>
                                                <p><strong>2.4. Usage Data: Data regarding your interactions with the App, such as the pages accessed, time spent on the App, and other usage patterns.</strong></p>
                                                <p><strong>2.3. Transaction Data: Information related to orders, payments, and delivery history.</strong></p>
                                                <p><strong>2.6. Payment Details:</strong> Your banking details, such as bank account number, bank name, account holder name, or mobile money account, which are necessary to process payments to you when you request it.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>3. Sharing Your Information</h3>
                                                <p>We may share your information with:</p>
                                                <p><strong>3.1.</strong> Service Providers: Third-party companies that assist in providing our services, such as payment processors, mapping services, and customer support.</p>
                                                <p><strong>3.2.</strong> Business Partners: Other companies and organizations with whom we collaborate to deliver the services, such as restaurants and delivery partners.</p>
                                                <p><strong>3.3.</strong> Legal Authorities: If required by law or to protect our rights, we may disclose your information to law enforcement or other authorities</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>4. Data Security</h3>
                                                <p><strong>4.1.</strong> We prioritize the security of your data and use various technical and organizational measures to protect your information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>5. Your Data Rights</h3>
                                                <p>Depending on your location, you may have certain rights regarding your personal data, such as:</p>
                                                <p><strong>5.1.</strong> Access and Correction: The right to access and correct your personal information.</p>
                                                <p><strong>5.2.</strong> Data Deletion: The right to request the deletion of your personal data.</p>
                                                <p><strong>5.3.</strong> Data Portability: The right to request a copy of your data in a portable format.</p>
                                                <p><strong>5.4.</strong> Opt-Out: The right to opt-out of certain data processing activities.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>6. Changes to This Privacy Policy</h3>
                                                <p><strong>6.1.</strong> We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes through the App or via email.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>7. Contact Us</h3>
                                                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                                                <p><strong>7.1.</strong> Email: info@delivas.co.za</p>
                                                <p><strong>7.2.</strong> Phone: 0794185925</p>
                                                <p><strong>7.3.</strong> Website: www.delivas.co.za</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="tab-pane fade" id="merchant" role="tabpanel" aria-labelledby="merchant-tab">
                            <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <h3 style={{marginBottom: 10}}>Delivas Privacy Policy for Merchants/Restaurants app</h3>
                                        <h3 style={{marginBottom: 50, fontSize: 20, textAlign: 'center'}}>Last Updated: 22/08/2024</h3>
                                        <div className="terms-container">
                                            <div className="terms-section">
                                                <h3>1. Introduction</h3>
                                                <p>Welcome to <strong>Delivas Merchants App </strong>("we", "our", "us"). We value the privacy of restaurants and merchants (“you,” “your”) who use our merchant app (the “App”) to accept and manage food orders. This Privacy Policy outlines how we collect, use, and protect the information that you provide or that is generated through your use of the App.</p>
                                                <p>By using the App, you consent to the collection and use of your information as described in this Privacy Policy. If you do not agree with our practices, please do not use the App.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>2. Information We Collect</h3>
                                                <p>We collect various types of information from merchants who use the App to facilitate order management and business operations.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>3. Personal Information</h3>
                                                <p><strong>3.1.</strong> Restaurant name, owner’s name, business address, phone number, and email address.</p>
                                                <p><strong>3.2.</strong> Payment information to process payments to you, such as bank account details.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>4. Business and Transaction Data</h3>
                                                <p>We collect information necessary to provide you with services, including:</p>
                                                <p><strong>4.1.</strong> Order details such as item names, prices, customer information, and order timestamps.</p>
                                                <p><strong>4.2.</strong> Transaction history, including payments received and pending amounts.</p>
                                                <p><strong>4.3.</strong> Sales data, including daily, weekly, and monthly reports.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>5. Device and Usage Data</h3>
                                                <p>When you use the App, we may automatically collect information about the device and how you use the App, including:</p>
                                                <p><strong>5.1.</strong> Device details (such as tablet or mobile device type, operating system version, and IP address).</p>
                                                <p><strong>5.2.</strong> App usage patterns, including features accessed, time spent on each section, and performance data.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>6. Location Data</h3>
                                                <p><strong>6.1.</strong> We may collect your business location information to better serve your geographic area or improve delivery coordination with customers.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>7. How We Use Your Information</h3>
                                                <p>We use the information collected to operate, manage, and improve the App and its related services, including:</p>
                                                <p><strong>7.1.</strong> Order Management: Facilitate order receipt, preparation, and delivery coordination.</p>
                                                <p><strong>7.2.</strong> Business Analytics: Provide reports and insights on sales, orders, and performance.</p>
                                                <p><strong>7.3.</strong> Payment Processing: Facilitate payments for completed orders and cashouts.</p>
                                                <p><strong>7.4.</strong> Customer Support: Resolve issues, provide customer service, and send important notifications.</p>
                                                <p><strong>7.5.</strong> App Improvements: Analyze usage patterns and feedback to improve the App’s functionality and features.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>8. Sharing Your Information</h3>
                                                <p>We do not sell or share your personal or business information with third parties for their marketing purposes. We may share your information with trusted partners, including:</p>
                                                <p><strong>8.1.</strong> Payment Processors: To facilitate payments to your account for orders fulfilled.</p>
                                                <p><strong>8.2.</strong> Service Providers: To perform tasks related to the operation of the App, such as hosting, analytics, and customer support.</p>
                                                <p><strong>8.2.</strong> Compliance with Legal Requirements: We may disclose information if required by law or to protect the rights, property, or safety of our users, company, or the public.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>9. Data Security</h3>
                                                <p><strong>9.1.</strong> We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no system can be 100% secure, and we cannot guarantee the absolute security of your data.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>10. Retention of Your Information</h3>
                                                <p><strong>10.1.</strong> We retain your personal and business information for as long as is necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>11. Your Choices and Rights</h3>
                                                <p>You have the right to:</p>
                                                <p><strong>11.1. </strong>Access and update your personal or business information through the App.</p>
                                                <p><strong>11.2. </strong>Request the deletion of your personal data, subject to legal obligations.</p>
                                                <p><strong>11.3. </strong>Opt-out of receiving non-essential communications from us.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>12. Changes to This Privacy Policy</h3>
                                                <p><strong>12.1.</strong> We may update this Privacy Policy from time to time. Any changes will be posted within the App and will become effective as of the date stated in the update. Your continued use of the App after any changes indicates your acceptance of the updated policy.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>7. Contact Us</h3>
                                                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                                                <p><strong>7.1.</strong> Email: info@delivas.co.za</p>
                                                <p><strong>7.2.</strong> Phone: 0794185925</p>
                                                <p><strong>7.3.</strong> Website: www.delivas.co.za</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                    </div>        

                    </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default PrivacyPolicy;
