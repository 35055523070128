import { createContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
export const AppContext = createContext();

const AppState = ({ children }) => {
    
    //const apiUrl = "http://127.0.0.1:8000/api"
    //const picUrl = "http://127.0.0.1:8000/uploads"
    const apiUrl = "https://api.delivas.co.za/api"
    const picUrl = "https://api.delivas.co.za/uploads"
    const [stores, setStores] = useState([]);
    const [storeErr, setStoreErr] = useState('');
    const [locationError, setLocationError] = useState('');

    useEffect(() => {
        const fetchData = async (coords) => {
            try {
                const res = await axios.post(`${apiUrl}/get-stores`, coords);
                setStores(res.data);
            } catch (error) {
                setStoreErr(error.response ? error.response.message : error.message);
            }
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    fetchData(position.coords);
                },
                (error) => {
                    alert('Error getting location:', error);
                    setLocationError(true);
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
            setLocationError(true);
        }
    }, []);
   
    const values = {
        picUrl, apiUrl, stores, storeErr
    }
    return (
        <AppContext.Provider value={values}>
            {children}
        </AppContext.Provider>
    );
}

export default AppState;