import React from 'react';

const TermsAndConditions = () => {
    return (
        <>
            {/* hero-section */}
            <section className="hero-section single-blog gap" style={{backgroundImage: "url(assets/img/background-3.png)"}} data-aos="flip-up"  data-aos-delay="300" data-aos-duration="400">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="offset-xl-2 col-xl-6 col-lg-12">
                                <div className="about-text">
                                    <h2>Terms and Conditions for our apps</h2>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            {/* tabs questions */}
            <section className="tabs">
                <div className="container">
                <div className="tabs-img-back">
                <div className="row">
                    <div className="offset-xl-2 col-xl-8 col-lg-12">
                        <div className="Provides">
                            <div className="nav nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link active" id="user-tab" data-bs-toggle="pill" data-bs-target="#user" type="button" role="tab" aria-controls="user" aria-selected="true">User App</button>
                                <button className="nav-link" id="driver-tab" data-bs-toggle="pill" data-bs-target="#driver" type="button" role="tab" aria-controls="driver" aria-selected="false">Driver App</button>
                                <button className="nav-link" id="merchant-tab" data-bs-toggle="pill" data-bs-target="#merchant" type="button" role="tab" aria-controls="merchant" aria-selected="false">Merchant App</button>
                            </div>
                        </div>
                    </div>

                    <div className="offset-xl-2 col-xl-8 col-lg-12 gap">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <h3 style={{marginBottom: 10}}>Delivas Terms and Conditions for Users/Customers app</h3>
                                        <h3 style={{marginBottom: 50, fontSize: 20, textAlign: 'center'}}>Last Updated: 22/08/2024</h3>
                                        <div className="terms-container">
                                            <div className="terms-section">
                                                <h3>1. Introduction</h3>
                                                <p>Welcome to <strong>Delivas</strong>, a food ordering and delivery platform. These Terms of Service ("Terms") govern your access to and use of our mobile application and related services (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>2. Acceptance of Terms</h3>
                                                <p>By registering and using the Delivas platform, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>3. Description of Service</h3>
                                                <p><strong>3.1.</strong> Delivas App provides users with the ability to browse menus, place food orders, and arrange for delivery from a variety of restaurants and food providers.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>4. User Responsibilities</h3>
                                                <p>As a user of the service, you agree to:</p>
                                                <p><strong>4.1.</strong> Provide accurate, current, and complete information during registration and account creation.</p>
                                                <p><strong>4.2.</strong> Maintain the confidentiality of your account credentials and notify us immediately of any unauthorized use.</p>
                                                <p><strong>4.3.</strong> Comply with all applicable laws and regulations in connection with your use of the Service.</p>
                                                <p><strong>4.4.</strong> Not use the Service for any unlawful or prohibited activities.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>5. Payment Terms</h3>
                                                <p><strong>5.1.</strong> Pricing: All prices listed in the app are in ZAR and are subject to change without notice.</p>
                                                <p><strong>5.2.</strong> Payment Processing: Payments are processed securely through our third-party payment processors. We do not store your payment information.</p>
                                                <p><strong>5.3.</strong> Refunds and Cancellations: Refund and cancellation policies are governed by the individual restaurant's policies and are detailed within the app.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>6. User-Generated Content</h3>
                                                <p><strong>6.1.</strong> Responsibility: You are responsible for any content you submit, including reviews, ratings, and comments.</p>
                                                <p><strong>6.2.</strong> Rights Granted: By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content in connection with the Service.</p>
                                                <p><strong>6.3.</strong> Prohibited Content: Do not submit content that is unlawful, defamatory, obscene, or infringes on the rights of others.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>7. Termination</h3>
                                                <p><strong>7.1.</strong> We reserve the right to suspend or terminate your access to the Service, without prior notice or liability, for any reason, including if you breach these Terms.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>8. Payments and Fees</h3>
                                                <p><strong>8.1.</strong> You agree to pay all fees associated with the Service. We may change our fees at any time. You will be compensated for deliveries made through the App as outlined in our driver payment policy. Payments are made according to the schedule and method specified in the App. We reserve the right to withhold or deduct payments for any violations of these Terms.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>9. Intellectual Property</h3>
                                                <p><strong>9.1.</strong> All content, trademarks, logos, and intellectual property displayed in the Service are the property of Delivas App or their respective owners. You are granted a limited, non-exclusive, non-transferable license to access and use the Service for personal, non-commercial purposes.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>10. Cancellation Policy</h3>
                                                <p><strong>10.1.</strong> Orders may not be cancelled on the app once placed, you may call the restaurant within specific timeframe to ask for cancellation from their side. Once the restaurant has started preparing your order, cancellations may not be possible.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>11. Disclaimer of Warranties</h3>
                                                <p><strong>11.1.</strong> The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that the Service will be uninterrupted, error-free, or secure.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>12. Limitation of Liability</h3>
                                                <p><strong>12.1.</strong> Delivas is not responsible for the quality of food provided by restaurants or the actions of delivery partners.</p>
                                                <p><strong>12.2.</strong> We are not liable for any indirect, incidental, special, or consequential damages arising out of your use of the App. Our total liability to you for any claims arising out of your use of the App is limited to the amount you paid, if any, to use the App.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>13. Indemnification</h3>
                                                <p><strong>13.1.</strong> You agree to indemnify, defend, and hold harmless Delivas, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your use of the Service or violation of these Terms.</p>
                                            </div>
                                            
                                            <div className="terms-section">
                                                <h3>14. Modifications to Terms</h3>
                                                <p><strong>14.1.</strong> Delivas reserves the right to modify these Terms and Conditions at any time. Continued use of the platform after changes constitutes acceptance of the new terms.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>15. Governing Law</h3>
                                                <p><strong>15.1.</strong> These Terms and Conditions shall be governed by and construed in accordance with the laws of South Africa.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="driver" role="tabpanel" aria-labelledby="driver-tab">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <h3 style={{marginBottom: 10}}>Delivas Terms and Conditions for Drivers app</h3>
                                        <h3 style={{marginBottom: 50, fontSize: 20, textAlign: 'center'}}>Last Updated: 22/08/2024</h3>
                                        <div className="terms-container">
                                            <div className="terms-section">
                                                <h3>1. Introduction</h3>
                                                <p>Welcome to <strong>Delivas</strong>, a food ordering and delivery platform. These Terms of Service ("Terms") govern your access to and use of our mobile application and related services (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>2. Acceptance of Terms</h3>
                                                <p>By using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and our Privacy Policy.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>3. Description of Service</h3>
                                                <p><strong>3.1.</strong> Delivas Driver App is a food delivery application that connects users with local restaurants and delivery partners.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>4. Eligibility</h3>
                                                <p><strong>4.1.</strong> To use our App, you must be at least 18 years old and have the legal capacity to enter into this agreement. By using the App, you represent that you meet these requirements.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>5. Use of the App</h3>
                                                <p><strong>5.1.</strong> You agree to use the App in accordance with all applicable laws and regulations. You must not misuse the App by knowingly introducing viruses or other harmful materials, or by attempting unauthorized access to the App, the server on which it is stored, or any server, computer, or database connected to our App.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>6. User Accounts</h3>
                                                <p><strong>6.1.</strong> You agree to use the App only for lawful purposes and in accordance with these Terms. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>7. User Conduct</h3>
                                                <p>You agree not to:</p>
                                                <p><strong>7.1.</strong> Misuse the App, including but not limited to reverse engineering, unauthorized access, or introducing viruses.</p>
                                                <p><strong>7.2.</strong> Engage in any activity that would disrupt or harm the App, its users, or our business.</p>
                                                <p><strong>7.3.</strong> Use the App to commit fraud or any other illegal activities.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>8. Driver Responsibilities</h3>
                                                <p>As a driver using our App, you agree to:</p>
                                                <p><strong>8.1.</strong> Deliver orders promptly and professionally.</p>
                                                <p><strong>8.2.</strong> Follow all applicable laws, including traffic and safety regulations.</p>
                                                <p><strong>8.3.</strong> Maintain a valid driver's license and insurance.</p>
                                                <p><strong>8.4.</strong> Communicate with customers and support in a respectful and timely manner.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>9. Payments and Fees</h3>
                                                <p><strong>9.1.</strong> You agree to pay all fees associated with the Service. We may change our fees at any time. You will be compensated for deliveries made through the App as outlined in our driver payment policy. Payments are made according to the schedule and method specified in the App. We reserve the right to withhold or deduct payments for any violations of these Terms.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>10. Termination</h3>
                                                <p><strong>10.1.</strong> We reserve the right to terminate or suspend your account at our discretion, without notice, if we believe you have violated these Terms or engaged in conduct that harms our business or users.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>11. Limitation of Liability</h3>
                                                <p><strong>11.1.</strong> We are not liable for any indirect, incidental, special, or consequential damages arising out of your use of the App. Our total liability to you for any claims arising out of your use of the App is limited to the amount you paid, if any, to use the App.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>12. Changes to Terms</h3>
                                                <p><strong>12.1.</strong> We may update these Terms of Service from time to time. The updated version will be effective as of the date specified in the notice. Continued use of the App after any changes constitute your acceptance of the new Terms.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>13. Indemnification</h3>
                                                <p><strong>13.1.</strong> You agree to indemnify, defend, and hold harmless Delivas, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your use of the Service or violation of these Terms.</p>
                                            </div>
                                            
                                            <div className="terms-section">
                                                <h3>14. Modifications to Terms</h3>
                                                <p><strong>14.1.</strong> Delivas reserves the right to modify these Terms and Conditions at any time. Continued use of the platform after changes constitutes acceptance of the new terms.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>15. Governing Law</h3>
                                                <p><strong>15.1.</strong> These Terms and Conditions shall be governed by and construed in accordance with the laws of South Africa.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="tab-pane fade" id="merchant" role="tabpanel" aria-labelledby="merchant-tab">
                            <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <h3 style={{marginBottom: 10}}>Delivas Terms and Conditions for Merchants/Restaurants app</h3>
                                        <h3 style={{marginBottom: 50, fontSize: 20, textAlign: 'center'}}>Last Updated: 22/08/2024</h3>
                                        <div className="terms-container">
                                            <div className="terms-section">
                                                <h3>1. Introduction</h3>
                                                <p>Welcome to <strong>Delivas</strong>, a food ordering and delivery platform. These Terms and Conditions govern the relationship between Delivas (hereinafter referred to as "we," "us," or "our") and the merchants/restaurants (hereinafter referred to as "you" or "your") using our platform.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>2. Acceptance of Terms</h3>
                                                <p>By registering and using the Delivas platform, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>3. Pricing Policy</h3>
                                                <p><strong>3.1.</strong> You agree to maintain prices on the Delivas platform that are consistent with the prices offered in your physical restaurant location.</p>
                                                <p><strong>3.2.</strong> Any significant discrepancies between in-store and app prices may result in suspension or termination of your account.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>4. Order Fulfillment</h3>
                                                <p><strong>4.1.</strong> You are responsible for fulfilling orders accurately and in a timely manner.</p>
                                                <p><strong>4.2.</strong> In the event of an incorrect order, Delivas reserves the right to refund the customer using funds from your account balance.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>5. Fees and Payments</h3>
                                                <p><strong>5.1.</strong> You agree to pay Delivas a commission fee of 10% on all sales made through the platform.</p>
                                                <p><strong>5.2.</strong> Commission fees will be automatically deducted from your earnings before withdrawal or cashout.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>6. Payouts and Bank Details</h3>
                                                <p><strong>6.1.</strong> Payouts to restaurants will be made to the banking details provided during the initial registration or application process.</p>
                                                <p><strong>6.2.</strong> It is the responsibility of the restaurant to ensure that the provided bank information is accurate and up to date, as Delivas will not be liable for any errors in payouts due to incorrect bank details.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>7. Refunds and Disputes</h3>
                                                <p><strong>7.1.</strong> Delivas reserves the right to issue refunds to customers in cases of incorrect orders, quality issues, or other valid complaints.</p>
                                                <p><strong>7.2.</strong> In such cases, the refund amount may be deducted from your account balance or future earnings.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>8. Account Management</h3>
                                                <p><strong>8.1.</strong> You are responsible for maintaining the accuracy of your restaurant information, menu items, and pricing on the Delivas platform.</p>
                                                <p><strong>8.2.</strong> Delivas reserves the right to suspend or terminate your account for violations of these Terms and Conditions.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>9. Intellectual Property</h3>
                                                <p><strong>9.1.</strong> You grant Delivas a non-exclusive license to use your restaurant name, logo, and menu information for marketing and promotional purposes.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>10. Liability</h3>
                                                <p><strong>10.1.</strong> Delivas is not liable for any direct, indirect, incidental, or consequential damages resulting from your use of the platform.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>11. Modifications to Terms</h3>
                                                <p><strong>11.1.</strong> Delivas reserves the right to modify these Terms and Conditions at any time. Continued use of the platform after changes constitutes acceptance of the new terms.</p>
                                            </div>

                                            <div className="terms-section">
                                                <h3>12. Governing Law</h3>
                                                <p><strong>12.1.</strong> These Terms and Conditions shall be governed by and construed in accordance with the laws of South Africa.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                    </div>        

                    </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default TermsAndConditions;
