import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../context/appContext';

const Restaturants = () => {

    const { stores, picUrl, locationError, storeErr } = useContext(AppContext)
    return (
        <>
            {/* hero-section */}
            <section className="hero-section about gap">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6" data-aos="fade-up"  data-aos-delay="200" data-aos-duration="300">
							<div className="about-text">
								<ul className="crumbs d-flex">
									<li><Link to={'/home'}>Home</Link></li>
									<li className="two"><a href=""><i className="fa-solid fa-right-long"></i>Restaurants</a></li>
								</ul>
								<h2>Restaurants</h2>
								<p>Egestas sed tempus urna et pharetra pharetra massa. Fermentum posuere urna nec tincidunt praesent semper.</p>
							</div>
						</div>
						<div className="col-lg-6" data-aos="fade-up"  data-aos-delay="300" data-aos-duration="400">
							<div className="restaurants-girl-img food-photo-section">
								<img alt="man" src="assets/img/photo-11.png" />
								<a href="#" className="one"><i className="fa-solid fa-burger"></i>Burgers</a>
								<a href="#" className="two"><i className="fa-solid fa-drumstick-bite"></i>Chicken</a>
								<a href="#" className="three"><i className="fa-solid fa-cheese"></i>Steaks</a>
								<a href="#" className="for"><i className="fa-solid fa-pizza-slice"></i>Fish</a>
							</div>
						</div>
					</div>
				</div>
	        </section>
            {/* all-restaurants  */}
            <section className="best-restaurants" style={{marginBottom: 50}}>
                <div className="container">
                    <div className="row">
                        {locationError && (
                            <div className="message-container">
                                <div className="message message-error">
                                    <i className="message-icon fa-solid fa-triangle-exclamation"></i>
                                    <p className="message-text">We could not get your current location</p>
                                </div>
                            </div>
                        )}
                        {storeErr ? (
                            <div className="message-container">
                                <div className="message message-error">
                                    <i className="message-icon fa-solid fa-triangle-exclamation"></i>
                                    <p className="message-text">{storeErr}</p>
                                </div>
                            </div>
                        ) : (
                            stores?.length === 0 ? (
                                <div className="message-container">
                                    <div className="message message-info">
                                        <i className="message-icon fa-solid fa-info-circle"></i>
                                        <p className="message-text">No stores available in your area.</p>
                                    </div>
                                </div>
                            ) : (
                                stores?.map((store, index)=> (
                                    <div key={index} className="col-lg-6" data-aos="flip-up"  data-aos-delay="200" data-aos-duration="300">
                                        <div className="logos-card restaurant-page">
                                            <img alt="logo" src={`${picUrl}/${store.image}`} style={{width: '100px', height: '100px', objectFit: 'cover'}} />
                                                <div className="cafa">
                                                    <h4>{store.brand_name}</h4>
                                                    <div>
                                                        <i className="fa-solid fa-star"></i>
                                                        <i className="fa-solid fa-star"></i>
                                                        <i className="fa-solid fa-star"></i>
                                                        <i className="fa-solid fa-star"></i>
                                                        <i className="fa-solid fa-star"></i>
                                                    </div>
                                                    <div className="cafa-button">
                                                        {store.food_categories.split(',').map((category, catIndex) => (
                                                            <a key={catIndex} href="#">{category}</a>
                                                        ))}
                                                    </div>
                                                    <p>{store.address}</p>
                                                </div>
                                        </div>
                                    </div>
                                ))
                            )
                        )}
                        
                    </div>
                </div>
            </section>
        </>
    );
}

export default Restaturants;
